<template>
    <div>
        <b-row>
            <b-col cols="2">
                <div class="vertical-layout h-100 color">
                    <div class="main-menu menu-fixed menu-accordion menu-shadow expanded">
                        <div class="navbar-header expanded">
                            <slot name="header">
                                <ul class="nav navbar-nav flex-row">
                                    <b-img :src="appLogoImage" alt="skileo"
                                    />
                                </ul>
                            </slot>
                        </div>
                    </div>
                </div>

            </b-col>
            <b-col cols="10" class="pt-4">

                <b-card no-body class="m-2 p-2">
                    <b-row class="pb-2">
                        <b-col>
                            <h1>Client list</h1>
                        </b-col>
                    </b-row>

                    <!-- FILTERS -->
                    <b-row>
                        <b-col>
                            <v-select
                                v-model="perPage"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                        </b-col>

                        <b-col>
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>


                    <!-- TABLE -->
                    <b-row>
                        <b-col>
                            <b-table
                                ref="refDocumentListTable"
                                :items="fetchApplicants"
                                responsive
                                :fields="tableColumns"
                                primary-key="id"
                                :sort-by.sync="sortBy"
                                show-empty
                                :empty-html="emptyHtml"
                                :sort-desc.sync="isSortDirDesc"
                                class="position-relative"
                                @refreshed="onTableRefresh"
                                v-model="currentItems"
                            >
                                <template v-slot:head(checkbox)="data">
                                    <span>{{ data.label.toUpperCase() }}</span>
                                    <b-form-checkbox
                                        class="d-inline-block pl-0"
                                        @change="onSelectAll"
                                        v-model="selectAllStatus"
                                    >
                                    </b-form-checkbox>
                                </template>

                                <template #cell(checkbox)="data">
                                    <b-form-checkbox
                                        v-model="selectedItems"
                                        :value="data.item.Id"
                                    />
                                </template>

                                <template #cell(Label)="data">
                                    <div class="media">
                                        <div class="media-aside align-self-center">
                                            <b-avatar
                                                @click="impersonate(data.item)"
                                                :src="data.item.ImageUrl"
                                                :text="
                                          (data.item.FirstName
                                            ? data.item.FirstName.charAt(0)
                                            : '') +
                                          (data.item.LastName
                                            ? data.item.LastName.charAt(0)
                                            : '')
                                        "
                                                class="badge-light-primary"
                                            >
                                            </b-avatar>
                                        </div>
                                        <div class="media-body">
                                            <b-link
                                                @click="impersonate(data.item)"
                                                class="font-weight-bold d-block text-nowrap"
                                            >
                                                {{ data.item.Label }}
                                            </b-link>
                                            <p class="mb-0">{{ data.item.Position }}</p>
                                        </div>
                                    </div>

                                </template>


                                <template #cell(CreatedAt)="data">
                                    <span>{{data.item.CreatedAt | date}}</span>
                                </template>


                                <template #cell(action)="data">
                                    <b-dropdown
                                        variant="link"
                                        toggle-class="p-0"
                                        no-caret
                                    >
                                        <template #button-content>
                                            <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="16"
                                                class="align-middle text-body"
                                            />
                                        </template>


                                        <b-dropdown-item @click="impersonate(data.item)">
                                            <feather-icon icon="UserCheckIcon"/>
                                            <span class="align-middle ml-50">
                                                {{i18nT(`Impersonate`) }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }}
                            {{ dataMeta.from }} {{ i18nT(`to`) }}
                            {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                            {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>

                </b-card>
            </b-col>
        </b-row>




    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
    BFormCheckbox, BFormInput, BFormGroup, BPagination, BImg,
} from 'bootstrap-vue'

import useAdminClientList from './useAdminClientList'
import {avatarText} from '@core/utils/filter'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import useAuth from "@/auth/useAuth";
import $store from "@/store";
import {getHomeRouteForLoggedInUser} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import {API_KEY, BASE_URL} from "@core/utils/constants";
import vSelect from "vue-select";
import {$themeConfig} from "@themeConfig";

export default {
    components: {
        BImg,
        vSelect,
        BPagination,
        BFormGroup, BFormInput,
        BRow,
        BCol,
        BCard,
        BTable,
        BDropdown,
        BDropdownItem,
        BLink,
        BFormCheckbox,
        BAvatar,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            currentItems: [],
            selectAllStatus: false,
        }
    },
    created() {},
    methods: {
        impersonate(user) {

            let data = {
                username: user.Login,
                admin_received_hash: user.Hash
            }

            this.$http.post('users/publicLogin', data)

            axios({
                method: 'post',
                url: `${BASE_URL}users/publicLogin`,
                headers: {
                    'Api-Key': API_KEY,
                    'Content-Type': 'application/json;charset=utf-8'
                },
               data
            }).then(response => {
                useAuth.setToken(response.data.data.token)
                useAuth.setCurWorkspace(response.data.data.current_workspace)
                useAuth.setWorkspaces(response.data.data.menu.workspaces)
                useAuth.setSoftwares(response.data.data.menu.apps)
                useAuth.setCurrentSoftware(response.data.data.current_sotftware)
                useAuth.setProfile(response.data.data.menu.user)
                useAuth.setUserType(response.data.data.user_type)
                useAuth.setUserPermissions(response.data.data.menu.user.Permissions)

                localStorage.setItem(
                    'userData',
                    JSON.stringify(response.data.data.menu.user)
                )

                $store.commit('app/UPDATE_LOCAL_USER', response.data.data.menu.user)

                let homeRedirectRoute =  getHomeRouteForLoggedInUser();


                window.open('/'+homeRedirectRoute) ;

                // this.$router
                //     .push({path: '/'+homeRedirectRoute})
                //     .then(() => {
                //         this.$toast({
                //             component: ToastificationContent,
                //             position: 'top-right',
                //             props: {
                //                 title: this.i18nT(`Welcome ${response.data.data.menu.user.FirstName} ${response.data.data.menu.user.LastName}`),
                //                 icon: 'CoffeeIcon',
                //                 variant: 'success',
                //                 text: this.i18nT(`You have successfully logged in.`)
                //             }
                //         })
                //         //window.location.reload();
                //     })
                //     .catch(err => {
                //         this.loginLoading = false;
                //         this.$toast({
                //             component: ToastificationContent,
                //             position: 'top-right',
                //             props: {
                //                 title: err ? err.message : 'Unable to login',
                //                 icon: 'AlertCircleIcon',
                //                 variant: 'danger',
                //                 text: this.i18nT(`Invalid email or password`)
                //             }
                //         })
                //     })
                })
                .catch(err => {
                    this.loginLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            // title: err ? err.response.data.message : 'Unable to login',
                            title: this.errorMessage(err),
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: this.i18nT(`Invalid email or password`)
                        }
                    })
                })
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.application.Id)
            } else {
                this.selectedItems = []
            }
        },
    },
    setup() {
        const { appName, appLogoImage } = $themeConfig.app;
        const {
            fetchApplicants,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            jobOffer,
            matchSkill,
            filterLabels,
            matchFolder,
            matchStage,
            cvs,
            refetchData
        } = useAdminClientList()

        return {
            appName,
            appLogoImage,
            fetchApplicants,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            jobOffer,
            matchSkill,
            filterLabels,
            matchFolder,
            matchStage,
            avatarText,
            cvs,
            refetchData,
            required
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    }

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.stage-container {
    background: white;
    border: 1px solid gray;
    border-right: 0px;
    overflow: hidden;

    .stageLink {
        border: none !important;
        border-radius: 0px;
    }

    .stageLink:focus,
    .stageLink:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 30px;
        color: #5e5873;
        font-weight: 300;
    }

    .stage-label {
        color: #5e5873;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: #5e5873;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.active-stage {
    background-color: $primary !important;

    span {
        color: white !important;
    }
}

.menu-section-title {
    padding: 16px;
    font-size: 8px;
}

/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type='text'] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: 0.5em 0.25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: 0.25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before,
.tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;

    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}

.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}

.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: 0.5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: 0.25em 1em;
    cursor: pointer;
}
</style>
