import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import useAuth from '@/auth/useAuth'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
const { i18n } = useI18nUtils()
function i18nT(str){
	return i18n(str)
}
export default function useAdminClientList(isTemplate) {
	const refDocumentListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'checkbox', label: '', thClass: 'tbl-chk pr-50', tdClass: 'tbl-chk'},
		{ key: 'Label', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1'},
		{ key: 'Company', label: i18nT(`Company`), sortable: true },
		{ key: 'Id', label: i18nT(`ID`), sortable: true },
		{ key: 'Email', label: i18nT(`E-mail`), sortable: true },
		{ key: 'CreatedAt', label: i18nT(`Created On`), sortable: true },
		{ key: 'PublicationStatus', label: i18nT(`Status`), sortable: true },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'tbl-actions',
		},
	]
	const perPage = ref(10)
	const totalDocuments = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('update')
	const isSortDirDesc = ref(true)
	const jobOffer = ref(null)
	const matchSkill = ref(null)
	const filterLabels = ref(null)
	const matchFolder = ref(null)
	const matchStage = ref(null)
	const cvs = ref([])

	const dataMeta = computed(() => {
		const localItemsCount = refDocumentListTable.value
			? refDocumentListTable.value.localItems.length
			: 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalDocuments.value,
		}
	})

	const refetchData = () => {
		refDocumentListTable.value.refresh()
	}

	watch(
		[
			currentPage,
			perPage,
			searchQuery,
			jobOffer,
			matchSkill,
			filterLabels,
			matchFolder,
			isSortDirDesc,
			sortBy,
			matchStage,
		],
		() => {
			refetchData()
		}
	)

	const fetchApplicants = (ctx, callback) => {

		const params = {
			page: currentPage.value,
			show_per_page: perPage.value,
			sort_by: sortBy.value,
			sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value
		}

		if (isTemplate) {
			params.templates = 1
		}
		// if (assignedTo.value != '') {
		//   params['filters[folder]'] = assignedTo.value;
		// }

		axios
			.get(`/system/panelClients`, {
				params,
			})
			.then(({ data }) => {
				cvs.value = data.data.clients
				callback(data.data.clients)
				//console.log("APPLICANTS ", data.data.records);
				totalDocuments.value = data.data.pagination.records_count
			})
	}

	return {
		fetchApplicants,
		tableColumns,
		perPage,
		currentPage,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refDocumentListTable,
		totalDocuments,
		filterLabels,
		matchStage,
		jobOffer,
		matchSkill,
		matchFolder,
		cvs,
		refetchData,
	}
}
